<template>
  <div>
    <CardsList
      :summary="items"
      item-id=""
    />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import CardsList from '../components/CardsList.vue'

export default {
  components: {
    CardsList,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState('dashboard', {
      items: 'items',
    }),
  },
  mounted() {
    this.fetchDashboardData()
  },
  methods: {
    ...mapActions('dashboard', {
      fetchItems: 'getItems',
    }),
    async fetchDashboardData() {
      try {
        await this.fetchItems()
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
